var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
exports = {
  name: "icon-ie",
  body: function iconIE() {
    if ((this || _global).childElementCount) return;
    (this || _global).innerHTML = "<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" preserveAspectRatio=\"xMidYMid meet\" width=\"100%\" height=\"100%\" viewBox=\"0 0 16 17\" class=\"icon\">" + "<g>" + "<path d=\"M14.947,3.453 C14.913,3.595 14.737,3.570 14.770,3.419 C14.972,2.831 15.434,1.403 14.594,0.723 C13.476,-0.190 11.153,0.953 9.910,1.725 C12.961,2.506 14.837,4.549 14.837,7.683 C14.837,7.952 14.879,8.196 14.862,8.540 C14.862,8.540 5.774,8.548 5.774,8.548 C5.833,10.220 6.740,11.349 8.059,11.349 C9.109,11.349 9.781,10.413 9.982,9.422 C9.982,9.422 14.770,9.430 14.770,9.430 C13.897,12.236 11.452,14.566 7.966,14.566 C7.461,14.566 6.981,14.532 6.526,14.467 C4.636,15.703 2.289,16.676 0.978,15.851 C-0.753,14.759 0.112,11.785 1.137,9.669 C1.137,9.669 1.139,9.672 1.139,9.672 C2.757,6.314 5.252,3.981 6.597,3.175 C6.589,3.175 6.589,3.201 6.597,3.175 C6.715,3.117 6.639,2.965 6.513,3.024 C5.713,3.440 2.697,5.015 0.735,8.088 C0.735,8.072 0.734,8.055 0.734,8.039 C0.734,6.107 1.507,4.435 2.901,3.159 C4.127,2.050 5.740,1.462 7.739,1.462 C8.235,1.462 8.710,1.501 9.164,1.572 C10.746,0.648 13.614,-0.719 14.879,0.420 C15.493,0.975 15.367,2.394 14.947,3.453 ZM3.304,13.188 C2.720,12.743 2.225,12.210 1.828,11.617 C1.175,13.092 0.849,14.604 1.591,15.162 C2.585,15.908 4.616,15.272 6.090,14.393 C5.020,14.182 4.093,13.781 3.304,13.188 ZM7.916,4.771 C6.824,4.771 5.782,5.089 5.757,6.542 C5.757,6.542 10.405,6.542 10.405,6.542 C10.405,5.122 9.067,4.771 7.916,4.771 Z\" style=\"fill:inherit\" fill-rule=\"evenodd\"></path>" + "</g>" + "</svg>";
  }
};
export default exports;